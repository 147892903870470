import Aura from '@primevue/themes/aura';
import { definePreset } from '@primevue/themes';
import tailwindPreset from './tailwind-preset';

/*
  This file defines any SupWiz theme overwrites
  We use PrimeVue's Aura theme as a base. Anything in this file
  are our custom overwrites
*/
const fontWeight600 = {
  font: {
    weight: 600,
  },
};
const lightBorderColor = '{surface.200}';

export default definePreset(Aura, {
  primitive: {
    borderRadius: {
      none: '0',
      xs: '0.1rem',
      sm: '0.2rem',
      md: '0.2rem',
      lg: '0.5rem',
      xl: '0.75rem',
    },
    ...tailwindPreset.theme.colors,
    slate: tailwindPreset.theme.colors.shade,
    stone: tailwindPreset.theme.colors.shade,
    surface: tailwindPreset.theme.colors.shade,
    neutral: tailwindPreset.theme.colors.shade,
    sky: tailwindPreset.theme.colors.blue,
  },
  semantic: {
    colorScheme: {
      light: {
        focusRing: {
          width: '3px',
        },
        formField: {
          focusRing: {
            color: '{primary.500}',
            style: 'solid',
            width: '2px',
          },
          borderColor: lightBorderColor,
        },
        content: {
          hoverBackground: '{surface.50}',
        },
        primary: tailwindPreset.theme.colors.primary,

      },
    },
  },
  components: {
    button: {
      colorScheme: {
        light: {
          secondary: {
            background: '{gray.700}',
            hoverBackground: '{gray.800}',
            activeBackground: '{gray.900}',
            borderColor: '{gray.700}',
            hoverBorderColor: '{gray.800}',
            activeBorderColor: '{gray.900}',
            color: '#fff',
            hoverColor: '#fff',
            activeColor: '#fff',
            focusRing: {
              color: '{gray.700}',
              shadow: 'none',
            },
          },
          'text.secondary.color': '{gray.600}',
        },
      },
      ...fontWeight600,
    },
    badge: {
      colorScheme: {
        light: {
          secondary: {
            background: '{gray.700}',
            color: '#fff',
          },
        },
      },
      ...fontWeight600,
    },
    datatable: {
      colorScheme: {
        light: {
          borderColor: lightBorderColor,
        },
      },
    },
    card: {
      colorScheme: {
        light: {
          shadow: '0px 0px 0.2rem 0px rgba(0,0,0, .075)',
          color: '{gray.700}',
        },
      },
    },
    inputgroup: {
      colorScheme: {
        light: {
          addon: {
            color: '{surface.600}',
          },
        },
      },
    },
    message: {
      colorScheme: {
        light: {
          info: {
            color: '{blue.700}',
          },
          secondary: {
            color: '{surface.700}',
          },
          warn: {
            background: 'color-mix(in srgb,var(--p-yellow-50),transparent 60%)',
            'border.color': '{yellow.100}',
            color: '{yellow.700}',
          },
        },
      },
    },
    tag: {
      colorScheme: {
        light: {
          secondary: {
            color: '{gray.800}',
          },
        },
      },
      ...fontWeight600,
    },
    toast: {
      colorScheme: {
        light: {
          secondary: {
            background: 'color-mix(in srgb, {gray.800}, transparent 7.5%)',
            'border.color': '{gray.800}',
            color: '#fff',
            'detail.color': '#fff',
            'close.button.hover.background': '{gray.500}',
          },
          success: {
            color: '{green.700}',
            'detail.color': '{green.700}',
          },
          error: {
            color: '{red.600}',
            'detail.color': '{red.600}',
          },
          warn: {
            color: '{yellow.700}',
            'detail.color': '{yellow.700}',
          },
        },
      },
    },
  },
});
